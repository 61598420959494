<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <div class="title_left">
              <span class="text-h5">{{ $t("DailyKM") }}{{ formTitle }}</span>
              <v-icon
                v-if="noDataPending"
                large
                color="green darken-2"
                class="ml-2"
              >
                mdi-check
              </v-icon>
            </div>
            <div class="title_right">
              <v-btn
                :color="current_approvalStatus_class"
                dark
                large
                class="btn_status"
              >
                {{ selected.approvalStatus }}
              </v-btn>
            </div>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-menu
                    ref="date_menu"
                    v-model="select_date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selected.date"
                        v-bind:label="$t('Date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="
                          selected.isLiquidationApprovedCount > 0
                            ? dateLqApprovedRules
                            : selected.id !==
                                selected.current_selected_date_data_id &&
                              selected.is_already_exist
                            ? dateRules
                            : selected.isDateOnHolidayLeave &&
                              !selected.isChecking
                            ? dateOnHolidayLeaveRules
                            : requiredRules
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selected.date"
                      no-title
                      @input="select_date_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      :min="selectable_mindate || ''"
                      :max="nowDate"
                      :reactive="reactive"
                      @change="onChangeData"
                      :readonly="selected.isChecking"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  (selected.isChecking && selected.routeId) ||
                    (!selected.isChecking && isGPV)
                "
              >
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="selected.routeId"
                    v-bind:label="$t('route')"
                    item-text="name"
                    item-value="id"
                    :items="filtered_routes"
                    :search-input.sync="search_routes"
                    :loading="isRoutesSearching"
                    hide-no-data
                    hide-selected
                    filled
                    :required="isGPV"
                    :rules="isGPV ? requiredRules : []"
                    @change="onChangeData"
                    :readonly="selected.isChecking"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-autocomplete
                    v-model="selected.vehicleType"
                    v-bind:label="$t('Vehicle Type')"
                    :items="vehicleTypeList"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="requiredRules"
                    :readonly="selected.isChecking"
                    @change="onChangeVehicleType"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-text-field
                    v-model="selected.startKM"
                    v-bind:label="$t('Start') + ' KM'"
                    :type="'number'"
                    @change="onChangeStartKM"
                    required
                    :rules="[kmNumRules]"
                    :readonly="selected.isChecking"
                  ></v-text-field>
                  <gennera-picture-input
                    v-if="!selected.isChecking"
                    :crop="false"
                    class="my-picture-input"
                    ref="pictureInputStartPhoto"
                    width="500"
                    height="300"
                    size="5"
                    :prefill="
                      getImageUri(
                        selected.startPhoto.static
                          ? selected.startPhoto.static
                          : null
                      )
                    "
                    accept="image/jpeg,image/png"
                    button-class="btn btn-outline-info"
                    aspect="Landscape"
                    :customStrings="{
                      upload: this.$t('uploading_image'),
                      change: this.$t('change'),
                      tap: 'toma una foto'
                    }"
                    @change="onPictureStartPhoto"
                    :capture="`camera`"
                  >
                  </gennera-picture-input>
                  <CoolLightBox
                    v-if="
                      selected.lightboxarr.length > 0 && selected.isChecking
                    "
                    :items="selected.lightboxarr"
                    :index="lightboxindex"
                    @close="lightboxindex = null"
                  >
                  </CoolLightBox>
                  <div
                    class="images-wrapper mb-2"
                    v-if="
                      selected.lightboxarr.length > 0 && selected.isChecking
                    "
                  >
                    <div
                      class="image lightbox_image_item"
                      :key="0"
                      @click="lightboxindex = 0"
                      :style="{
                        backgroundImage: 'url(' + selected.lightboxarr[0] + ')'
                      }"
                    ></div>
                  </div>
                  <v-btn
                    v-if="selected.startPhoto.static"
                    color="blue darken-1"
                    @click="downloadSelectedImage(selected.startPhoto.static)"
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ $t("Download") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-text-field
                    v-model="selected.endKM"
                    v-bind:label="$t('End') + ' KM'"
                    :type="'number'"
                    @change="onChangeEndKM"
                    required
                    :rules="[kmNumRules]"
                    :readonly="selected.isChecking"
                  ></v-text-field>
                  <gennera-picture-input
                    v-if="!selected.isChecking"
                    :crop="false"
                    class="my-picture-input"
                    ref="pictureInputEndPhoto"
                    width="500"
                    height="300"
                    size="5"
                    :prefill="
                      getImageUri(
                        selected.endPhoto.static
                          ? selected.endPhoto.static
                          : null
                      )
                    "
                    accept="image/jpeg,image/png"
                    button-class="btn btn-outline-info"
                    aspect="Landscape"
                    :custom-strings="{
                      upload: this.$t('uploading_image'),
                      change: this.$t('change'),
                      tap: 'toma una foto'
                    }"
                    @change="onPictureEndPhoto"
                    :capture="`camera`"
                  >
                  </gennera-picture-input>
                  <div
                    class="images-wrapper mb-2"
                    v-if="
                      selected.lightboxarr.length > 0 && selected.isChecking
                    "
                  >
                    <div
                      class="image lightbox_image_item"
                      :key="0"
                      @click="lightboxindex = 1"
                      :style="{
                        backgroundImage: 'url(' + selected.lightboxarr[1] + ')'
                      }"
                    ></div>
                  </div>
                  <v-btn
                    v-if="selected.endPhoto.static"
                    color="blue darken-1"
                    @click="downloadSelectedImage(selected.endPhoto.static)"
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ $t("Download") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="selected.totalKM"
                    label="Total KM"
                    :type="'number'"
                    readonly
                    :required="!selected.isChecking && selected.totalKM < 0"
                    :rules="
                      !selected.isChecking && selected.totalKM < 0
                        ? [kmTotalNumRules]
                        : []
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="selected.gpv_comment"
                    v-bind:label="$t('comments') + ' ' + $t('User')"
                    :readonly="selected.isChecking"
                    ref="textarea_gpv_comment"
                    v-bind:class="
                      'gpv_comment_text ' +
                        (selected.isChecking ? 'readonly' : '')
                    "
                    :required="
                      (!selected.isChecking &&
                        (selected.totalKM >= 150 ||
                          this.selected.other_users_data_count > 0)) ||
                        (!selected.isChecking && !isGPV)
                    "
                    :rules="
                      (!selected.isChecking &&
                        (selected.totalKM >= 150 ||
                          this.selected.other_users_data_count > 0)) ||
                      (!selected.isChecking && !isGPV)
                        ? requiredRules
                        : []
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="selected.isChecking">
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="selected.approvalStatus"
                    v-bind:label="$t('salesforce.status')"
                    :items="status_type"
                    filled
                    required
                    :rules="requiredRules"
                    @change="onChangeApprovalStatus(selected.approvalStatus)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="selected.id !== undefined">
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="selected.spv_comment"
                    v-bind:label="
                      $t('comments') + ' ' + $t('expensesParentUserName')
                    "
                    :readonly="!selected.isChecking"
                    ref="textarea_spv_comment"
                    v-bind:class="
                      'spv_comment_text ' +
                        (!selected.isChecking ? 'readonly' : '')
                    "
                    :required="
                      selected.isChecking &&
                        selected.approvalStatus === 'Incidencia'
                    "
                    :rules="
                      selected.isChecking &&
                      selected.approvalStatus === 'Incidencia'
                        ? requiredRules
                        : []
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                selected.id !== undefined &&
                  !selected.isChecking &&
                  selected.initialApprovalStatus === 'Incidencia'
              "
              color="blue darken-1"
              text
              :loading="isDeleting"
              @click="onClickDelete(selected)"
              ref="btnDelete"
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-if="
                selected.isChecking ||
                  (!selected.isChecking &&
                    selected.initialApprovalStatus !== 'Aprobado')
              "
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-if="selected && selected_image"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Image</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 100%;" :src="selected_image" alt="" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click="downloadImage(selected, 'startPhoto')"
          >
            <b-icon icon="download" aria-hidden="true"></b-icon>
            {{ $t("Download") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeImageDialog">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ve-table
      id="kilometers-table"
      fixed-header
      max-height="calc(100vh - 330px)"
      :columns="isteam === 'my' ? columns : spv_columns"
      :table-data="tableData"
      :border-around="true"
      :border-x="true"
      :border-y="true"
      :scroll-width="1500"
      :sort-option="sortOption"
      :cell-style-option="cellStyleOption"
    />
    <div v-show="showEmpty" class="empty-data">Data Empty.</div>
    <div class="table-pagination mt-2 text-right">
      <ve-pagination
        :total="totaltableData"
        :page-index="pageIndex"
        :page-size="pageSize"
        :page-size-option="pageOptions"
        @on-page-number-change="pageNumberChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";
import PictureInput from "vue-picture-input";
import download from "js-file-download";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import GenneraPictureInput from "@/components/GenneraPictureInput.vue";

export default {
  name: "Kilometers",
  props: ["isteam", "year", "month", "userId"],
  components: {
    PictureInput,
    GenneraPictureInput,
    CoolLightBox
  },
  data() {
    return {
      lightboxindex: null,
      status_type: ["Pendiente Aprobación", "Incidencia", "Aprobado"],
      user_roles: [],
      user_status: [],
      expenseTypes: [],
      vehicleTypeList: [],
      tableData: [],
      totaltableData: 0,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        date: {},
        username: {},
        companyName: {},
        parent_username: {},
        approvername: {},
        user_status: {},
        usertype: {},
        expenseTypeId: {},
        approvalStatus: {},
        totalKM: {},
        startKM: {},
        endKM: {},
        parentId: {},
        vehicleType: {}
      },
      filterable_col_list: [
        "date",
        "username",
        "companyName",
        "user_status",
        "usertype",
        "parent_username",
        "route",
        "approvername",
        "gpv_comment",
        "spv_comment",
        "expenseTypeId",
        "approvalStatus",
        "startKM",
        "endKM",
        "totalKM",
        "parentId",
        "vehicleType"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (row.approvalStatus === "Aprobado") {
            return "table-body-cell-approved";
          } else if (row.approvalStatus === "Incidencia") {
            return "table-body-cell-incidence";
          }
        }
      },
      showEmpty: false,

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      kmNumRules: v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Este campo debe estar por encima de 0";
      },
      kmTotalNumRules: v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Los KM de inicio no pueden ser mayores que los KM final";
      },
      dateRules: [v => !!v || "Required", v => "La fecha ya tiene KM"],
      dateLqApprovedRules: [
        v => !!v || "Required",
        v => "No se pueden anadir kms porque la liquidacion ha sido aprobada."
      ],
      dateOnHolidayLeaveRules: [
        v => !!v || "Required",
        v => "No se puede crear KM porque está de vacaciones o de bajas."
      ],
      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      search_routes: null,
      isRoutesSearching: false,
      filtered_routes: [],
      default_routes: [],
      select_date_menu: false,
      nowDate: new Date().toISOString().slice(0, 10),
      reactive: false,
      current_approvalStatus_class: "error",
      isSaving: false,
      selectable_mindate: new Date().toISOString().slice(0, 10),
      isDeleting: false,

      /********** image dialog options **********/
      showImageDialog: false,
      selected_image: null,

      filterable_parent_list: []
    };
  },
  watch: {
    $route(to, from) {
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        this.filterCriteria[key] = Object.assign({});
      }
      this.init();
    },
    async search_routes(val) {
      this.isRoutesSearching = true;
      let fetch_url = `expenses/get_selectable_routes?filter_name=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_routes = resp.data;
      this.isRoutesSearching = false;
      if (this.filtered_routes.length === 0) {
        this.filtered_routes = this.default_routes;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isGPV", "user"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? ""
        : " - " + this.$t("edititem");
    },
    noDataPending() {
      const {
        date,
        routeId,
        startKM,
        endKM,
        startPhoto,
        endPhoto,
        current_selected_date_data_id,
        id,
        is_already_exist,
        isChecking
      } = this.selected;
      if (
        date &&
        startKM >= 0 &&
        endKM >= 0 &&
        (startPhoto.img || startPhoto.static) &&
        (endPhoto.img || endPhoto.static) &&
        (id === current_selected_date_data_id || !is_already_exist)
      ) {
        if (!isChecking && this.isGPV && !routeId) {
          return false;
        }
        return true;
      }
      return false;
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "desc",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_username() {
      return {
        key: "username",
        title: this.$t("salesforce.UserName"),
        field: "username",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && row.user.name + " " + row.user.surname}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["username"]}
                  apiUrl={`expenses/km/getfilterlist/${this.isteam}`}
                  columnName={`username`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["username"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "username")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_companyName() {
      return {
        key: "companyName",
        title: "Nombre de empresa",
        field: "companyName",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.user?.company && row.user.company.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["companyName"]}
                  apiUrl={`expenses/km/getfilterlist/${this.isteam}`}
                  columnName={`companyName`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["companyName"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "companyName")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_userstatus() {
      return {
        key: "user_status",
        title: `${this.$t("salesforce.status")} usuario`,
        field: "user_status",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && this.gennera_user_status[row.user.status]}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["user_status"]}
                  dataList={this.user_status}
                  filterCriteria={this.filterCriteria["user_status"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user_status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_usertype() {
      return {
        key: "usertype",
        title: this.$t("salesforce.user_type"),
        field: "usertype",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user && row.user.role}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["usertype"]}
                  dataList={this.user_roles}
                  filterCriteria={this.filterCriteria["usertype"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "usertype")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_parentId() {
      return {
        key: "parentId",
        title: this.$t("expensesParentUserName"),
        field: "parentId",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.user &&
                row.user.Parent &&
                row.user.Parent.name + " " + row.user.Parent.surname}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["parentId"]}
                  apiUrl={`expenses/km/getfilterlist/${this.isteam}`}
                  columnName={`parentId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["parentId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "parentId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_route() {
      return {
        key: "route",
        title: this.$t("route"),
        field: "route",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route && row.route.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["route"]}
                  on-input={value => (this.filterCriteria["route"] = value)}
                  placeholder="Search Route Name"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_totalKM() {
      return {
        key: "totalKM",
        title: "Total KM",
        field: "totalKM",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["totalKM"]["from"]}
                  value={this.filterCriteria["totalKM"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["totalKM"]["to"]}
                  value={this.filterCriteria["totalKM"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "totalKM")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_startKM() {
      return {
        key: "startKM",
        title: this.$t("Start" + " KM"),
        field: "startKM",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startKM"]["from"]}
                  value={this.filterCriteria["startKM"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startKM"]["to"]}
                  value={this.filterCriteria["startKM"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "startKM")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_startPhoto() {
      return {
        key: "startPhoto",
        title: this.$t("Photo Start"),
        field: "startPhoto",
        align: "left",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          if (row.startPhoto !== null) {
            let thumbnail_url = this.getThumbnailUri(row.startPhoto);
            return (
              <img
                style="border: 1px solid #aaa; cursor: pointer;"
                src={thumbnail_url}
                alt=""
                on-click={() => this.onItemShowImageDialog(row, row.startPhoto)}
              />
            );
          } else {
            return <span>&nbsp;</span>;
          }
        }
      };
    },
    col_endKM() {
      return {
        key: "endKM",
        title: this.$t("End" + " KM"),
        field: "endKM",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endKM"]["from"]}
                  value={this.filterCriteria["endKM"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endKM"]["to"]}
                  value={this.filterCriteria["endKM"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "endKM")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_endPhoto() {
      return {
        key: "endPhoto",
        title: this.$t("Photo End"),
        field: "endPhoto",
        align: "left",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          if (row.endPhoto !== null) {
            let thumbnail_url = this.getThumbnailUri(row.endPhoto);
            return (
              <img
                style="border: 1px solid #aaa; cursor: pointer;"
                src={thumbnail_url}
                alt=""
                on-click={() => this.onItemShowImageDialog(row, row.endPhoto)}
              />
            );
          } else {
            return <span>&nbsp;</span>;
          }
        }
      };
    },
    col_approvalStatus() {
      return {
        key: "approvalStatus",
        title: this.$t("salesforce.status"),
        field: "approvalStatus",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "right",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["approvalStatus"]}
                  dataList={this.status_type}
                  filterCriteria={this.filterCriteria["approvalStatus"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "approvalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_gpv_comment() {
      return {
        key: "gpv_comment",
        title: this.$t("comments") + " " + this.$t("User"),
        field: "gpv_comment",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["gpv_comment"]}
                  on-input={value =>
                    (this.filterCriteria["gpv_comment"] = value)
                  }
                  placeholder="Search Comments"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "gpv_comment")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_spv_comment() {
      return {
        key: "spv_comment",
        title: this.$t("comments") + " " + this.$t("expensesParentUserName"),
        field: "spv_comment",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["spv_comment"]}
                  on-input={value =>
                    (this.filterCriteria["spv_comment"] = value)
                  }
                  placeholder="Search Comments"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "spv_comment")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_approvername() {
      return {
        key: "approvername",
        title: this.$t("Approved By"),
        field: "approvername",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.approver && row.approver.name + " " + row.approver.surname}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["approvername"]}
                  apiUrl={`expenses/km/getfilterlist/${this.isteam}`}
                  columnName={`approvername`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["approvername"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "approvername")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_go() {
      return {
        key: "go",
        title: this.$t("go"),
        field: "go",
        align: "center",
        width: 80,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.onItemEdit(row)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    col_vehicleType() {
      return {
        key: "vehicleType",
        title: this.$t("Vehicle Type"),
        field: "vehicleType",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.vehicleTypeLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                {this.vehicleTypeList.map(item => {
                  return (
                    <v-checkbox
                      v-model={this.filterCriteria["vehicleType"][item.value]}
                      label={item.label}
                      value={item.value}
                    ></v-checkbox>
                  );
                })}
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "vehicleType")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    spv_columns() {
      return [
        this.col_date,
        this.col_username,
        this.col_companyName,
        this.col_userstatus,
        this.col_usertype,
        this.col_parentId,
        this.col_route,
        this.col_vehicleType,
        this.col_totalKM,
        this.col_startKM,
        this.col_startPhoto,
        this.col_endKM,
        this.col_endPhoto,
        this.col_gpv_comment,
        this.col_spv_comment,
        this.col_approvername,
        this.col_approvalStatus,
        this.col_go
      ];
    },
    columns() {
      return [
        this.col_date,
        this.col_route,
        this.col_vehicleType,
        this.col_totalKM,
        this.col_startKM,
        this.col_startPhoto,
        this.col_endKM,
        this.col_endPhoto,
        this.col_gpv_comment,
        this.col_spv_comment,
        this.col_approvername,
        this.col_approvalStatus,
        this.col_go
      ];
    }
  },
  methods: {
    setDateFrom() {
      let twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
      twoMonthsAgo = moment(twoMonthsAgo)
        .local()
        .format("YYYY-MM-DD");
      this.filterCriteria["date"] = {
        from: twoMonthsAgo,
        to: ""
      };
    },
    onChangeApprovalStatus(val) {
      switch (val) {
        case "Aprobado":
          this.current_approvalStatus_class = "success";
          break;
        case "Incidencia":
          this.current_approvalStatus_class = "warning";
          break;
        case "Pendiente Aprobación":
          this.current_approvalStatus_class = "primary";
          break;
        default:
          this.current_approvalStatus_class = "error";
          break;
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#kilometers-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "startKM" ||
        cancelFilterKey === "endKM" ||
        cancelFilterKey === "totalKM"
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (
        cancelFilterKey === "route" ||
        cancelFilterKey === "gpv_comment" ||
        cancelFilterKey === "spv_comment"
      ) {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "date") {
            let convert_value = {};
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
            }
            if (Object.keys(convert_value).length > 0) {
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (
            key === "startKM" ||
            key === "endKM" ||
            key === "totalKM"
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#kilometers-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      if (this.isteam !== undefined) {
        query_params_string += "&isteam=" + this.isteam;
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "expenses/km";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.expenseTypes = resp.expenseTypes;
        this.vehicleTypeList = resp.vehicleTypeList;
        this.filtered_routes = resp.selecteable_routes;
        this.default_routes = resp.selecteable_routes;
        const { whole_totalKM, costPerKM } = resp;
        this.$store.dispatch("expenses/setMonthlyKMData", {
          whole_totalKM,
          costPerKM
        });
        this.$store.dispatch(
          "expenses/setStatisticsData1KM",
          resp.statistics_data1_km
        );
        this.filterable_parent_list = resp.parent_list;
        this.user_roles = resp.user_roles;
        this.user_status = resp.user_status;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    onItemShowImageDialog(item, image) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.selected_image = this.getImageUri(image ? image : null);
      this.showImageDialog = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    onChangeStartKM() {
      this.changeKM();
    },
    onChangeEndKM() {
      this.changeKM();
    },
    changeKM() {
      if (
        this.selected.startKM !== undefined &&
        this.selected.endKM !== undefined
      ) {
        this.selected.startKM = parseFloat(this.selected.startKM);
        this.selected.endKM = parseFloat(this.selected.endKM);
        this.selected.totalKM =
          parseFloat(this.selected.endKM) -
          parseFloat(this.selected.startKM) -
          // this.deductAmountPerdayKM;
          this.user.discount_km;
      }
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        date: _.get(item, "date"),
        userId: _.get(item, "userId"),
        routeId: _.get(item, "routeId"),
        vehicleType: _.get(item, "vehicleType"),
        startKM: _.get(item, "startKM"),
        endKM: _.get(item, "endKM"),
        totalKM: _.get(item, "totalKM"),
        approvalStatus: _.get(item, "approvalStatus"),
        initialApprovalStatus: _.get(item, "approvalStatus"),
        gpv_comment: _.get(item, "gpv_comment"),
        spv_comment: _.get(item, "spv_comment"),
        startPhoto: {
          img: null,
          static: _.get(item, "startPhoto")
        },
        endPhoto: {
          img: null,
          static: _.get(item, "endPhoto")
        },
        approver: _.get(item, "approver"),
        route: _.get(item, "route"),
        user: _.get(item, "user"),
        other_users_data_count: 0,
        is_already_exist: false,
        current_selected_date_data_id: undefined,
        isChecking: false,
        isLiquidationApprovedCount: 0,
        isDateOnHolidayLeave: false
      };
      if (!model.vehicleType) model.vehicleType = "OWNVEHICLE";
      // this.selectable_mindate = this.getFirstdayOfOneMonthsAgo(this.nowDate);
      if (model.id === undefined) {
        model.isChecking = false;
      } else if (model.userId !== this.user.id) {
        model.isChecking = true;
      }

      if (model.route !== undefined) {
        this.filtered_routes = [model.route];
      } else if (model.id === undefined && this.isGPV) {
        this.filtered_routes = this.default_routes;
        if (this.filtered_routes.length > 0) {
          model.routeId = this.filtered_routes[0].id;
        }
      }

      if (model.id === undefined) {
        model.approvalStatus = "Pendiente Aprobación";
      }
      this.onChangeApprovalStatus(model.approvalStatus);
      model.lightboxarr = [];
      if (model.startPhoto.static) {
        model.lightboxarr.push(this.getImageUri(model.startPhoto.static));
      }
      if (model.endPhoto.static) {
        model.lightboxarr.push(this.getImageUri(model.endPhoto.static));
      }
      if (model.id === undefined) {
        if (this.$refs["pictureInputStartPhoto"] !== undefined) {
          this.$refs["pictureInputStartPhoto"].removeImage();
        }
        if (this.$refs["pictureInputEndPhoto"] !== undefined) {
          this.$refs["pictureInputEndPhoto"].removeImage();
        }
      }
      console.log(model);
      return model;
    },
    onPictureStartPhoto(img) {
      this.selected.startPhoto.img = this.$refs["pictureInputStartPhoto"].file;
    },
    onPictureEndPhoto(img) {
      this.selected.endPhoto.img = this.$refs["pictureInputEndPhoto"].file;
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    getThumbnailUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getThumbnailUri(imgStatic);
    },
    async downloadImage(selected, downloadFile) {
      try {
        const response = await ApiService.get(this.selected_image, {
          responseType: "arraybuffer"
        });
        download(response, "download.png");
      } catch (error) {
        // this.close();
      }
    },
    async downloadSelectedImage(downloadFile) {
      let selected_image = this.getImageUri(downloadFile ? downloadFile : null);
      try {
        const response = await ApiService.get(selected_image, {
          responseType: "arraybuffer"
        });
        download(response, "download.png");
      } catch (error) {
        // this.close();
      }
    },
    async onSaveClick(item) {
      const response = await this.get_initial_data(
        this.selected.date,
        this.selected.routeId
      );
      if (response.isDateOnHolidayLeave) {
        this.selected.isDateOnHolidayLeave = response.isDateOnHolidayLeave;
      }
      if (response !== null) {
        this.selected.other_users_data_count = response.other_users_data_count;
      }
      if (
        !this.selected.isChecking &&
        this.selected.totalKM >= 150 &&
        !this.selected.gpv_comment
      ) {
        logError("Has hecho más de 150KM. Por favor rellena los comentarios.");
        this.$refs.textarea_gpv_comment.focus();
      }
      if (
        !this.selected.isChecking &&
        this.selected.other_users_data_count > 0 &&
        !this.selected.gpv_comment
      ) {
        logError(
          "Esta ruta ya tiene un usuario que ha pasado kilómetros ese mismo día, por favor complete el motivo de los comentarios."
        );
        this.$refs.textarea_gpv_comment.focus();
      }
      if (
        this.selected.isChecking &&
        this.selected.approvalStatus === "Incidencia" &&
        !this.selected.spv_comment
      ) {
        logError("Los comentarios del responsable son necesarios.");
        this.$refs.textarea_spv_comment.focus();
      }
      if (this.$refs.form.validate()) {
        if (item.startKM > item.endKM) {
          logError("Los KM de inicio no pueden ser mayores que los KM final.");
          return;
        }
        let is_valid = true;

        if (is_valid) {
          try {
            this.isSaving = true;
            let body = _.omit(item, ["startPhoto", "endPhoto"]);
            let imgStart = item.startPhoto.img;
            let imgEnd = item.endPhoto.img;
            if (imgStart) {
              let { data } = await ApiService.uploadImage(imgStart);
              body.startPhotoId = data.id;
            } else if (!item.startPhoto.static) {
              logError("Se requiere una foto de inicio.");
              this.isSaving = false;
              return;
            }
            if (imgEnd) {
              let { data } = await ApiService.uploadImage(imgEnd);
              body.endPhotoId = data.id;
            } else if (!item.endPhoto.static) {
              logError("Se requiere una foto final.");
              this.isSaving = false;
              return;
            }
            if (this.noDataPending && !this.selected.isChecking) {
              body.approvalStatus = "Pendiente Aprobación";
            }
            console.log(body);
            if (body.id) {
              await ApiService.put(`expenses/km/${body.id}`, body);
            } else {
              await ApiService.post(`expenses/km`, body);
            }
            this.$store.dispatch("auth/getGlobalState");
            logInfo("Éxito !");
            this.showEdit = false;
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    async get_initial_data(date, routeId) {
      try {
        let fetch_url =
          "expenses/km/get_initial_data/" +
          date +
          "/" +
          routeId +
          "/" +
          this.selected.userId;
        const response = await ApiService.get(fetch_url);
        return response;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async onChangeData() {
      let date = this.selected.date;
      let routeId = this.selected.routeId;
      const response = await this.get_initial_data(date, routeId);
      if (response !== null) {
        if (response.data !== null) {
          this.selected.is_already_exist = true;
          this.selected.current_selected_date_data_id = response.data.id;
        } else {
          this.selected.is_already_exist = false;
          this.selected.current_selected_date_data_id = undefined;
        }
        this.selected.other_users_data_count = response.other_users_data_count;
        this.selected.isLiquidationApprovedCount =
          response.isLiquidationApprovedCount;
        this.selected.isDateOnHolidayLeave = response.isDateOnHolidayLeave;
      }
    },
    async onChangeVehicleType() {
      // if (this.selected.vehicleType !== "OWNVEHICLE") {
      //   this.selected.startKM = 0;
      //   this.selected.endKM = 0;
      //   this.changeKM();
      // }
    },
    async onClickDelete(item) {
      if (window.confirm(this.$t("confirmdelete"))) {
        this.isDeleting = true;
        try {
          await ApiService.delete(`expenses/km/${item.id}`);
          this.isDeleting = false;
          this.getDataFromApi();
          this.showEdit = false;
          logInfo("Éxito !");
        } catch (error) {
          console.log(error);
          this.isDeleting = false;
        }
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "expenses/km/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "kmdata.xlsx");
      this.close();
    },
    init() {
      this.setDateFrom();
      this.getDataFromApi();
    }
  },
  mounted() {
    if (Object.keys(this.$route.params).length > 0) {
      if (
        this.$route.params.year &&
        this.$route.params.month &&
        this.$route.params.userId
      ) {
        this.criteria = {
          year: this.$route.params.year,
          month: this.$route.params.month,
          userId: this.$route.params.userId
        };
        let start_end_date = this.getFirstAndLastDateOfMonth(
          this.criteria.year,
          this.criteria.month
        );
        this.filterCriteria.date = {
          from: start_end_date.firstDate,
          to: start_end_date.lastDate
        };
        this.filterCriteria.userId = this.criteria.userId;
      }
    }
    this.init();
  }
};
</script>

<style>
.status_wrapper {
  text-align: center;
}
.status_wrapper .row {
  margin-top: 0;
  color: #000;
}
#kilometers-table tbody img {
  width: 100% !important;
}
input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: "" !important;
}
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
.lightbox_image_item {
  max-width: 100%;
  height: 300px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title_wrapper .btn_status {
  cursor: initial;
}
.picture-inner,
.picture-preview {
  z-index: 1000 !important;
}
.error--text input[type="number"] {
  color: #f00;
}
</style>
