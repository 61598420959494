var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.selected)?_c('v-dialog',{attrs:{"scrollable":"","max-width":"600px"},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',{staticClass:"title_wrapper"},[_c('div',{staticClass:"title_left"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("DailyKM"))+_vm._s(_vm.formTitle))]),(_vm.noDataPending)?_c('v-icon',{staticClass:"ml-2",attrs:{"large":"","color":"green darken-2"}},[_vm._v(" mdi-check ")]):_vm._e()],1),_c('div',{staticClass:"title_right"},[_c('v-btn',{staticClass:"btn_status",attrs:{"color":_vm.current_approvalStatus_class,"dark":"","large":""}},[_vm._v(" "+_vm._s(_vm.selected.approvalStatus)+" ")])],1)]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-menu',{ref:"date_menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('Date'),"prepend-icon":"mdi-calendar","readonly":"","required":"","rules":_vm.selected.isLiquidationApprovedCount > 0
                          ? _vm.dateLqApprovedRules
                          : _vm.selected.id !==
                              _vm.selected.current_selected_date_data_id &&
                            _vm.selected.is_already_exist
                          ? _vm.dateRules
                          : _vm.selected.isDateOnHolidayLeave &&
                            !_vm.selected.isChecking
                          ? _vm.dateOnHolidayLeaveRules
                          : _vm.requiredRules},model:{value:(_vm.selected.date),callback:function ($$v) {_vm.$set(_vm.selected, "date", $$v)},expression:"selected.date"}},'v-text-field',attrs,false),on))]}}],null,false,2121944752),model:{value:(_vm.select_date_menu),callback:function ($$v) {_vm.select_date_menu=$$v},expression:"select_date_menu"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"locale":this.$i18n.locale,"min":_vm.selectable_mindate || '',"max":_vm.nowDate,"reactive":_vm.reactive,"readonly":_vm.selected.isChecking},on:{"input":function($event){_vm.select_date_menu = false},"change":_vm.onChangeData},model:{value:(_vm.selected.date),callback:function ($$v) {_vm.$set(_vm.selected, "date", $$v)},expression:"selected.date"}})],1)],1)],1),(
                (_vm.selected.isChecking && _vm.selected.routeId) ||
                  (!_vm.selected.isChecking && _vm.isGPV)
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('route'),"item-text":"name","item-value":"id","items":_vm.filtered_routes,"search-input":_vm.search_routes,"loading":_vm.isRoutesSearching,"hide-no-data":"","hide-selected":"","filled":"","required":_vm.isGPV,"rules":_vm.isGPV ? _vm.requiredRules : [],"readonly":_vm.selected.isChecking},on:{"update:searchInput":function($event){_vm.search_routes=$event},"update:search-input":function($event){_vm.search_routes=$event},"change":_vm.onChangeData},model:{value:(_vm.selected.routeId),callback:function ($$v) {_vm.$set(_vm.selected, "routeId", $$v)},expression:"selected.routeId"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('Vehicle Type'),"items":_vm.vehicleTypeList,"item-text":"label","item-value":"value","filled":"","required":"","rules":_vm.requiredRules,"readonly":_vm.selected.isChecking},on:{"change":_vm.onChangeVehicleType},model:{value:(_vm.selected.vehicleType),callback:function ($$v) {_vm.$set(_vm.selected, "vehicleType", $$v)},expression:"selected.vehicleType"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Start') + ' KM',"type":'number',"required":"","rules":[_vm.kmNumRules],"readonly":_vm.selected.isChecking},on:{"change":_vm.onChangeStartKM},model:{value:(_vm.selected.startKM),callback:function ($$v) {_vm.$set(_vm.selected, "startKM", $$v)},expression:"selected.startKM"}}),(!_vm.selected.isChecking)?_c('gennera-picture-input',{ref:"pictureInputStartPhoto",staticClass:"my-picture-input",attrs:{"crop":false,"width":"500","height":"300","size":"5","prefill":_vm.getImageUri(
                      _vm.selected.startPhoto.static
                        ? _vm.selected.startPhoto.static
                        : null
                    ),"accept":"image/jpeg,image/png","button-class":"btn btn-outline-info","aspect":"Landscape","customStrings":{
                    upload: this.$t('uploading_image'),
                    change: this.$t('change'),
                    tap: 'toma una foto'
                  },"capture":`camera`},on:{"change":_vm.onPictureStartPhoto}}):_vm._e(),(
                    _vm.selected.lightboxarr.length > 0 && _vm.selected.isChecking
                  )?_c('CoolLightBox',{attrs:{"items":_vm.selected.lightboxarr,"index":_vm.lightboxindex},on:{"close":function($event){_vm.lightboxindex = null}}}):_vm._e(),(
                    _vm.selected.lightboxarr.length > 0 && _vm.selected.isChecking
                  )?_c('div',{staticClass:"images-wrapper mb-2"},[_c('div',{key:0,staticClass:"image lightbox_image_item",style:({
                      backgroundImage: 'url(' + _vm.selected.lightboxarr[0] + ')'
                    }),on:{"click":function($event){_vm.lightboxindex = 0}}})]):_vm._e(),(_vm.selected.startPhoto.static)?_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){return _vm.downloadSelectedImage(_vm.selected.startPhoto.static)}}},[_c('b-icon',{attrs:{"icon":"download","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("Download"))+" ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('End') + ' KM',"type":'number',"required":"","rules":[_vm.kmNumRules],"readonly":_vm.selected.isChecking},on:{"change":_vm.onChangeEndKM},model:{value:(_vm.selected.endKM),callback:function ($$v) {_vm.$set(_vm.selected, "endKM", $$v)},expression:"selected.endKM"}}),(!_vm.selected.isChecking)?_c('gennera-picture-input',{ref:"pictureInputEndPhoto",staticClass:"my-picture-input",attrs:{"crop":false,"width":"500","height":"300","size":"5","prefill":_vm.getImageUri(
                      _vm.selected.endPhoto.static
                        ? _vm.selected.endPhoto.static
                        : null
                    ),"accept":"image/jpeg,image/png","button-class":"btn btn-outline-info","aspect":"Landscape","custom-strings":{
                    upload: this.$t('uploading_image'),
                    change: this.$t('change'),
                    tap: 'toma una foto'
                  },"capture":`camera`},on:{"change":_vm.onPictureEndPhoto}}):_vm._e(),(
                    _vm.selected.lightboxarr.length > 0 && _vm.selected.isChecking
                  )?_c('div',{staticClass:"images-wrapper mb-2"},[_c('div',{key:0,staticClass:"image lightbox_image_item",style:({
                      backgroundImage: 'url(' + _vm.selected.lightboxarr[1] + ')'
                    }),on:{"click":function($event){_vm.lightboxindex = 1}}})]):_vm._e(),(_vm.selected.endPhoto.static)?_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){return _vm.downloadSelectedImage(_vm.selected.endPhoto.static)}}},[_c('b-icon',{attrs:{"icon":"download","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("Download"))+" ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Total KM","type":'number',"readonly":"","required":!_vm.selected.isChecking && _vm.selected.totalKM < 0,"rules":!_vm.selected.isChecking && _vm.selected.totalKM < 0
                      ? [_vm.kmTotalNumRules]
                      : []},model:{value:(_vm.selected.totalKM),callback:function ($$v) {_vm.$set(_vm.selected, "totalKM", $$v)},expression:"selected.totalKM"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{ref:"textarea_gpv_comment",class:'gpv_comment_text ' +
                      (_vm.selected.isChecking ? 'readonly' : ''),attrs:{"label":_vm.$t('comments') + ' ' + _vm.$t('User'),"readonly":_vm.selected.isChecking,"required":(!_vm.selected.isChecking &&
                      (_vm.selected.totalKM >= 150 ||
                        this.selected.other_users_data_count > 0)) ||
                      (!_vm.selected.isChecking && !_vm.isGPV),"rules":(!_vm.selected.isChecking &&
                      (_vm.selected.totalKM >= 150 ||
                        this.selected.other_users_data_count > 0)) ||
                    (!_vm.selected.isChecking && !_vm.isGPV)
                      ? _vm.requiredRules
                      : []},model:{value:(_vm.selected.gpv_comment),callback:function ($$v) {_vm.$set(_vm.selected, "gpv_comment", $$v)},expression:"selected.gpv_comment"}})],1)],1),(_vm.selected.isChecking)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('salesforce.status'),"items":_vm.status_type,"filled":"","required":"","rules":_vm.requiredRules},on:{"change":function($event){return _vm.onChangeApprovalStatus(_vm.selected.approvalStatus)}},model:{value:(_vm.selected.approvalStatus),callback:function ($$v) {_vm.$set(_vm.selected, "approvalStatus", $$v)},expression:"selected.approvalStatus"}})],1)],1):_vm._e(),(_vm.selected.id !== undefined)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{ref:"textarea_spv_comment",class:'spv_comment_text ' +
                      (!_vm.selected.isChecking ? 'readonly' : ''),attrs:{"label":_vm.$t('comments') + ' ' + _vm.$t('expensesParentUserName'),"readonly":!_vm.selected.isChecking,"required":_vm.selected.isChecking &&
                      _vm.selected.approvalStatus === 'Incidencia',"rules":_vm.selected.isChecking &&
                    _vm.selected.approvalStatus === 'Incidencia'
                      ? _vm.requiredRules
                      : []},model:{value:(_vm.selected.spv_comment),callback:function ($$v) {_vm.$set(_vm.selected, "spv_comment", $$v)},expression:"selected.spv_comment"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),(
              _vm.selected.id !== undefined &&
                !_vm.selected.isChecking &&
                _vm.selected.initialApprovalStatus === 'Incidencia'
            )?_c('v-btn',{ref:"btnDelete",attrs:{"color":"blue darken-1","text":"","loading":_vm.isDeleting},on:{"click":function($event){return _vm.onClickDelete(_vm.selected)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeEditDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),(
              _vm.selected.isChecking ||
                (!_vm.selected.isChecking &&
                  _vm.selected.initialApprovalStatus !== 'Aprobado')
            )?_c('v-btn',{ref:"btnSave",attrs:{"color":"blue darken-1","text":"","loading":_vm.isSaving},on:{"click":function($event){return _vm.onSaveClick(_vm.selected)}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.selected && _vm.selected_image)?_c('v-dialog',{attrs:{"scrollable":"","width":"auto"},model:{value:(_vm.showImageDialog),callback:function ($$v) {_vm.showImageDialog=$$v},expression:"showImageDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Image")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.selected_image,"alt":""}})])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){return _vm.downloadImage(_vm.selected, 'startPhoto')}}},[_c('b-icon',{attrs:{"icon":"download","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("Download"))+" ")],1),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeImageDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1):_vm._e(),_c('ve-table',{attrs:{"id":"kilometers-table","fixed-header":"","max-height":"calc(100vh - 330px)","columns":_vm.isteam === 'my' ? _vm.columns : _vm.spv_columns,"table-data":_vm.tableData,"border-around":true,"border-x":true,"border-y":true,"scroll-width":1500,"sort-option":_vm.sortOption,"cell-style-option":_vm.cellStyleOption}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmpty),expression:"showEmpty"}],staticClass:"empty-data"},[_vm._v("Data Empty.")]),_c('div',{staticClass:"table-pagination mt-2 text-right"},[_c('ve-pagination',{attrs:{"total":_vm.totaltableData,"page-index":_vm.pageIndex,"page-size":_vm.pageSize,"page-size-option":_vm.pageOptions},on:{"on-page-number-change":_vm.pageNumberChange,"on-page-size-change":_vm.pageSizeChange}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }